<template>
    <div>
            <HeaderXS></HeaderXS>
            <div class="container">
                <div class="row">
                    <h2 class="col-12 text-center">Vielen Dank, Ihre Zahlung ist bei uns eingegangen</h2>
                </div>
            </div>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <h3>Ihre Rechnung erhalten Sie bei Abholung.</h3>
                        <CartTableXS v-if="cart.product != 'coupon'"></CartTableXS>
                        <CouponCartTableXS v-if="cart.product == 'coupon'"></CouponCartTableXS>
                    </div>
                </div>

                <div class="row" v-if="cart.product == 'coupon'">
                    <div class="col-12" v-if="cart.deliveryType == 'pickup'">
                        <p>Sie können Ihren Gutschein ab sofort bei uns:<br><br>
                            Autohaus Israel GmbH & Co. KG<br>
                            Dohnaer Straße 310<br>
                            01257 Dresden<br><br>
                            Montag bis Freitag 06:30–19:00 Uhr und<br> Samstags 09:00–13:00 Uhr abholen.
                        </p>
                    </div>
                    <div class="col-12" v-if="cart.deliveryType == 'postal'">
                        <p>Wir werden Ihren Gutschein in den nächsten Tagen per post verschicken.
                        </p>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        Eine Kopie dieser Rechnung erhalten Sie per E-Mail.
                    </div>
                </div>
            </div>
            <FooterXS></FooterXS>
    </div>
</template>
<script>
    import CartTableXS from "@/components/CartTableXS.vue";
    import CouponCartTableXS from "@/components/CouponCartTableXS.vue";
    import HeaderXS from "@/components/HeaderXS.vue";
    import FooterXS from "@/components/FooterXS.vue";

    import axios from 'axios';

    export default {
        name: 'CheckOutCheck',
        components: {
            CartTableXS,
            CouponCartTableXS,
            HeaderXS,
            FooterXS,
        },
        props: ['order_id'],
        data() {
            return {
                cart: this.$store.state.cart,
            }
        },
        mounted(){
            axios.interceptors.request.use(function(config) {
                var container = document.querySelector('#app > div');
                var loader = document.querySelector('.loader');
                if(container && !loader){
                    var temp = `
                    <center class="white"><br><br><br>
                    <div class="loader" id="loader"></div>
                    <div class="loader" id="loader2"></div>
                    <div class="loader" id="loader3"></div>
                    <div class="loader" id="loader4"></div>
                    <span id="text">Bitte warten, Ihre Zahlung wird überprüft</span><br>
                    </center>
                    `;

                    // document.querySelector('#app > div').style.display = 'none';
                    container.innerHTML = temp + container.innerHTML;
                }
                return config
            }, function(error) {
                document.querySelector('#app > div').removeChild(document.querySelector('center'));
                this.CheckOutText = "Es ist ein Fehler aufgetreten"
                return Promise.reject(error);
            });
            axios
                .post(`https://api.opel-dresden.de/api/payment/data?id=${this.order_id}`, {
                })
                .then(response => {
                    if(response.data.status !== "Success"){
                        document.querySelector('#app > div').removeChild(document.querySelector('center'));

                        this.$store.commit({
                            type: 'setError',
                            error: true
                        });
                        this.$router.push({name: 'CheckOut', hash: '#payment'})

                    }
                    else{
                        document.querySelector('#app > div').removeChild(document.querySelector('center'));
                    }
                })
                .catch(() => {
                    document.querySelector('#app > div').removeChild(document.querySelector('center'));

                    this.$store.commit({
                        type: 'setError',
                        error: true
                    });
                    this.$router.push({name: 'CheckOut', hash: '#payment'})

                });
        },
    }
</script>
<style lang="scss" scoped>

    h3{
        margin-bottom: 20px;
    }
    ul{
        li{

            font-size: 21px;
            letter-spacing: 0.53px;
            line-height: 30px;

            &.spacer{
                display:block;
                height: 1em;
            }
            &.deposit{
                color: #456FEF;
                font-family: 'futura-pt-bold';
            }
            &.deposit-info{
                font-weight: 400;
                font-style: italic;
            }

            .round{
                font-weight:500;
                display: inline-block;
                border-radius: 90px;
                border: thin solid #000;
                width: 1.5em;
                height: 1.5em;
                padding-left: 0.6em;
            }
        }
    }

    .billing-address{
        padding-top: 100px;
    }

    .table{
        background-color: #FFF
    }

    form{
        padding-top:100px;

        .payment-list-entry{
            font-size:21px;
            padding-left:0;

            input{
                display:none;
            }

            label{
                padding-bottom:40px;
                font-family: 'futura-pt';
            }

            input:checked+label{
                font-family: 'futura-pt-bold';
                img{
                    border: thin solid #e3eafd;
                }
            }
        }

        .form-control{
            height: 65px;
            border: none;
        }
    }
    center.white{
        background-color: #FFF !important;
    }


</styles>